@font-face {
    font-family: regular;
    src: url('../Assets/font/AdobeCleanRegular.otf');
  }

  @font-face {
    font-family: bold;
    src: url('../Assets/font/AdobeCleanBold.otf');
  }

  @font-face {
    font-family: italic;
    src: url('../Assets/font/AdobeCleanIt.otf');
  }

  @media only screen and (max-width: 750px){
      .Onboard-page{
        .sidebar{
            display: none;
        }
        .lg-page-banner{
            width: 0% !important;
            overflow: hidden;
        }
        .lg-page-form{
            width: 96% !important;
            margin: 0 auto;
            padding: 10px;
            .first-row{
                label{
                    margin-top: 0rem !important;
                }
            }
            .second-row{
                flex-direction: column;
                .pairCard{
                    width: 100% !important;
                    padding: 1rem 0rem;
                    .box-col {
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: nowrap;
                        align-items: center;
                        padding: 0px !important;
                        div{
                            flex-shrink:1;
                        }
                    }
                }

                .midChain{
                    display: none;
                }
            }
            .third-row{
                display: none;
            }
            .third-row-mobile{
                display: block !important;
                
                margin: 1rem;
                border: 1px solid lightgray;
                padding:10px;
                .top-head{
                    font-size: 20px;
                    font-weight: bold;
                }
                .eachCard{
                    // padding: 1rem;
                    border: 1px solid lightgray;
                    border-radius: 5px;
                    margin: 1rem 0rem;
                    font-size: 13px;
                    font-family: regular;
                }
            }
            
        }
    }
}

a{
    cursor: pointer;
}
button{
    cursor:pointer
}
.Onboard-page{

    width: 100%;
    height: 100%;
    display: flex;
    .lg-page-banner{
        width: 15%;
        height: 100vh;
        display: flex;
        // background-color: #2f327d;    
        background-color: black;
        color: lightgrey;
        .sideBar{
            width:100%;
            .img-area{
                display:flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding:1rem;
                margin-top: 3rem;
                img{
                    // width:35%;
                    width: 74px;
                    height: 55px;
                    margin-top: 5.5rem;
                }
                p{
                    font-family: bold;
                    color: white;
                    width: 60%;
                    font-size: 15px;
                    // margin-top: 5%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-wrap:wrap;
                    div{
                        margin: auto;
                        margin-top: 1rem;
                        padding: 0.5rem;
                    }
                    span{
                        font-family: regular;
                        font-size: 12px;
                        margin: auto;
                        text-align: center;
                    }
                }
            }
            .menu_area{
                width:100%;
                // margin-top: 80px;
                .row{
                    display: flex;
                    margin-top: 30px;
                    img{
                        width: 22%;
                        height: 15%;
                        margin-top: -16px;
                        margin-left: 1rem;
                    }
                    p{
                        margin-left: -14px;
                        font-family: bold;
                        font-size: 15px;
                        width: 70%;
                    }
                }
            }
        }    
    }
    .lg-page-form{
        width:85%;
        height: fit-content;
        box-shadow: 0 3px 6px #00000029 ;
        position: relative;
        padding:10px;
        .first-row{
            width:100%;
            display: flex;
            label{
                width:40%;
                font-size:30px;
                font-family: bold;
                margin-left: 1rem;
                margin-top: 1rem;
               
            }
            input{
                width: 40%;
                border:1px solid lightgray;
                border-radius: 2px;
                font-size: 15px;
                font-family: regular;
                height: 2rem;
                outline: none;
                margin-top: 1rem;
                padding: 2px 10px;
                margin-left: 15%;
            }
            a{
                margin-top: 1rem;
                padding:10px;
                background-color: #21201C;
                border-radius: 2px;
                height: 2rem;
                width: 2rem;
                margin-left: 10px;
            }
        }
        .second-form{
            width:90%;
            margin-left: 5%;
            margin-top: 1rem;
            border:1px solid lightgray;
            display: flex;
            padding:3px;
            .coling{
                width:5%;
                img{
                    width:100%;
                }
            }
            .coling1{
                width:16%;
                display: flex;
                justify-content: center;
                align-items: center;
                
                p{
                    margin: 0;
                    font-size: 15px;
                    font-family: bold;
                }
            }

        }
        .third-row{
            display:flex;
            width:95%;
            margin-left: 2%;
            margin-top: 1rem;
            .col-md-6{
                display: flex;
                img{
                    width: 7%;
                }
                p{
                    font-size: 15px;
                    font-family: bold;
                    margin-top: 20px;
                    margin-left: 20px;
                    span{
                        font-size: 12px;
                        font-family: regular;
                    }
                }
                a{
                    font-size: 15px;
                    font-family: bold;
                    margin-top: 5%;
                    margin-left: 20px;
                    color:#2f327d;
                }
            }
            .add-user{
                justify-content: end;
            }
        }
        .fourth-row{
            margin-top: 1rem;
            width:95%;
            margin-left: 2%;
            p{
                font-family: italic;
                font-size: 15px;
            }
        }
        .fivth-row{
            display: flex;
            width:92%;
            margin-left: 4%;
            margin-bottom: 2rem;
            .col-md-4{
                label{
                    font-size: 15px;
                    font-family: regular;
                    margin-top: 2rem;
                }
                input{
                    margin-top: 10px;
                    font-size: 15px;
                    font-family: regular;
                    width: 90%;
                    height: 2rem;
                    border: 1px solid lightgray;
                    border-radius:3px;
                    outline: none;
                    padding: 5px 10px;
                }
                input::placeholder{
                    color:#DBDBDB;
                    font-size: 15px;
                }
            }
        }
        .sixth-row{
            display: flex;
            width:92%;
            margin-left: 4%;
            margin-bottom: 2rem;
            .col-md-4{
                margin-top: 2rem;
                height: 100%;
                #para{
                    font-family: regular;
                    font-size: 12px;
                    margin-top: 2%;
                }
                #paradiv{
                    display: flex;
                    img{
                        width: 10%;
                    }
                }
                p{
                    font-family: bold;
                    font-size: 15px;
                }
                div{
                    display: flex;
                    p{
                        font-family: regular;
                        font-size: 12px;
                        color: #5197CB;                        
                    }
                    label{
                        display: inline-block;
                        font-size: 13px;
                        color: blue;
                        padding: 0.5rem;
                        font-family: sans-serif;
                        border-radius: 0.3rem;
                        cursor: pointer;
                        font-family: bold;
                    }
                }
                input{
                    width: 100%;
                    border:1px solid lightgray;
                    border-radius: 2px;
                    font-size: 15px;
                    font-family: regular;
                    height: 2rem;
                    outline: none;
                    padding: 2px 10px;
                }
            }
            .col-md-6{
                margin-top: 2rem;
                height: 100%;
                #para{
                    font-family: regular;
                    font-size: 12px;
                    margin-top: 2%;
                }
                #paradiv{
                    display: flex;
                    img{
                        width: 10%;
                    }
                }
                p{
                    font-family: bold;
                    font-size: 15px;
                }
                div{
                    display: flex;
                    p{
                        font-family: regular;
                        font-size: 12px;
                        color: #5197CB;                        
                    }
                    label{
                        display: inline-block;
                        font-size: 13px;
                        color: blue;
                        padding: 0.5rem;
                        font-family: sans-serif;
                        border-radius: 0.3rem;
                        cursor: pointer;
                        font-family: bold;
                    }
                }
            }
        }
        .seventh-row{
            margin-top: 1rem;
            width: 100%;
            display: flex;
            justify-content: end;
            button{
                padding: 5px 20px;
                background: #21201C;
                border: 1px solid #21201C;
                border-radius: 3px;
                // color:#FFD069;
                color: lightgrey;
                font-family: bold;
                width: 150px;
               
            }
        }
        .face-row{
            display: flex;
            width:95%;
            margin-left: 2%;
            margin-top: 2rem;
            label{
                font-size:20px;
                font-family: bold;
            }
            p{
                font-size: 15px;
                font-family: regular;
            }
            #para{
                color: #0f878c;
                font-family: regular;
                font-size: 15px;
                margin-top: 2rem;
            }
            #para1{
                color:#9A9A9A;
                font-family: regular;
                font-size: 12px;
                margin-left: 5%;
                margin-top: -2%;
            }
        }
    }
}