@font-face {
    font-family: regular;
    src: url('../Assets/font/AdobeCleanRegular.otf');
  }

  @font-face {
    font-family: bold;
    src: url('../Assets/font/AdobeCleanBold.otf');
  }

  @font-face {
    font-family: italic;
    src: url('../Assets/font/AdobeCleanIt.otf');
  }


@media only screen and (max-width: 750px){
    .sidebar{
        display: none;
    }
    .listed-page{
        .lg-page-form{
            .first-row{
                label{
                    margin-top: 0rem !important;
                }
            }
            .second-row{
                flex-direction: column;
                .pairCard{
                    width: 100% !important;
                    padding: 1rem 0rem;
                    .box-col {
                        display: flex;
                        justify-content: space-around;
                        flex-wrap: nowrap;
                        align-items: center;
                        padding: 0px !important;
                        div{
                            flex-shrink:1;
                        }
                    }
                }

                .midChain{
                    display: none;
                }
            }
            .third-row{
                display: none;
            }
            .third-row-mobile{
                display: block !important;
                
                margin: 1rem;
                border: 1px solid lightgray;
                padding:10px;
                .top-head{
                    font-size: 20px;
                    font-weight: bold;
                }
                .eachCard{
                    // padding: 1rem;
                    border: 1px solid lightgray;
                    border-radius: 5px;
                    margin: 1rem 0rem;
                    font-size: 13px;
                    font-family: regular;
                }
            }
            
        }
    }
}
@media only screen and (max-width: 768px){
    .listed-page {
        .lg-page-form{
            .Detail-row-sec{
                margin-top: 0px !important;
                width: 100%;
                padding: 10px;
                display: flex;
                justify-content: space-around;
               div{
                width: 45%;
                margin-right: 0% !important;
                .row{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
               }
            }
        }
    }

    
}

@media only screen and (max-width: 992px){
    .pdf{
        width: 100% !important;
        height: 550px !important;
    }
}


a{
    cursor: pointer;
}
button{
    cursor:pointer
}
.listed-page{

    width: 100%;
    height: 100%;
    display: flex;
    .lg-page-banner{
        width: 15%;
        height: 100vh;
        display: flex;
        // background-color: #2f327d;    
        background-color: black;
        color: lightgrey;
        .sideBar{
            width:100%;
            .img-area{
                display:flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding:1rem;
                margin-top: 3rem;
                img{
                    // width:35%;
                    width: 74px;
                    height: 55px;
                    margin-top: 5.5rem;
                }
                p{
                    font-family: bold;
                    color: white;
                    width: 100%;
                    font-size: 15px;
                    // margin-top: 5%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-wrap:wrap;
                    div{
                        margin: auto;
                        margin-top: 1rem;
                        padding: 0.5rem;
                    }
                    span{
                        font-family: regular;
                        font-size: 12px;
                        margin: auto;
                        text-align: center;
                    }
                }
            }
            .menu_area{
                width:100%;
                // margin-top: 80px;
                .row{
                    display: flex;
                    margin-top: 30px;
                    img{
                        width: 22%;
                        height: 15%;
                        margin-top: -6px;
                        margin-left: 1rem;
                    }
                    p{
                        text-wrap: wrap;
                        margin-left: -14px;
                        font-family: bold;
                        font-size: 15px;
                        width: 100%;
                        padding:0px 20px;
                    }
                }
            }
        }
        
    }
    
    .lg-page-form{
        width:85%;
        height: fit-content;
        box-shadow: 0 3px 6px #00000029 ;
        position: relative;
        // padding:1rem;
        .first-row{
            width:100%;
            display: flex;
            label{
                width:30%;
                font-size:40px;
                font-family: bold;
                margin-left: 1rem;
                margin-top: 1rem;
               
            }
            input{
                width: 40%;
                border:1px solid lightgray;
                border-radius: 2px;
                height: 2rem;
                outline: none;
                margin-top: 1rem;
                padding: 2px 10px;
                margin-left: 15%;
                font-size: 15px;
                font-family: regular;
            }
            a{
                margin-top: 1rem;
                padding:10px;
                background-color: #21201C;
                border-radius: 2px;
                height: 2rem;
                width: 2rem;
                margin-left: 10px;
            }
        }
        .second-row{
            display: flex;
            margin-top: 1%;
            width: 98%;
            padding: 1rem;
            margin: 0 auto;
            .pairCard{
                width: 50%;
                .eachCard{
                    width: 50%;
                    // margin-left: -10px;
                    margin: 0 0.5rem;
                    .box-col{
                        border: 1px solid lightgray;
                        border-radius: 4px;
                        width:100%;
                        height: 100%;
                        margin-left:2%;
                        padding:10px;
                        p{
                            font-size: 15px;
                            font-family: bold;
                            span{
                                font-family: regular;
                                font-size: 12px;
                            }
                        }
                        .col-md-4{
                            p{
                                font-size: 30px;
                                font-family: bold;
                                margin-top: 32%;
                            }
                        }
                    }
                }
            }
        }
        .third-row{
            // border:1px solid lightgray;
            width:96%;
            margin-left: 1%;
            margin-top: 1%;
            padding:5px;
            height: fit-content;
            p{
                font-family: bold;
                font-size: 20px;
                span{
                    font-size: 15px;
                    font-family: italic;
                }
            }
            .row{
                // height: 35px;
                // margin-top: -7px;
                .col{
                    center{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        input{
                            margin-top: -15px;
                            font-size: 15px;
                            font-family: regular;
                        }
                        p{
                            color: #000000;
                            font-size: 13px;
                            font-family: regular;
                        }
                    }
                }
                
            }
            .pagination{
                margin-top: 1rem;
                margin-bottom: 1rem;
                .pagin{
                    display: flex;
                    margin-left: 80%;
                    a{
                        padding:5px;
                        background-color: #877bf0;
                    }
                }
            }
        }
        .third-row-mobile{
            display: none;
        }
        #third-row{
            border:1px solid lightgray;
            .row{
                // height:21px;
            }
            .col-div{
                margin-top: 20px;
            }
            .top-head{
                display: flex;
                justify-content: space-between;
                a{
                    margin-left: 45%;
                    margin-top: 15px;
                    color: #1887A8;
                    span{
                        font-size: 12px;
                    }
                }
            }
            #para{
                font-size: 12px;
                font-family: regular;
            }
            #label{
                font-size: 12px;
                font-family: bold;
            }
        }
        .Detail-row-one{
            display: flex;
            border: 1px solid lightgray;
            width: 96%;
            margin-left: 2%;
            margin-top: 1rem;
            padding: 1rem;
            img{
                width: 116px;
                height: 116px;
                margin-top: 1.5rem;
            }
            div{
                margin-left: 2%;
                label{
                    font-size: 30px;
                    margin-top: 1rem;
                    font-family: bold;
                }
                p{
                    font-size: 15px;
                    font-family: regular;
                }
                #label{
                    font-size:15px;
                    font-family: bold;
                }
                a{
                    color:#1E93BE;
                    font-size:15px;
                    font-family: bold;
                }
            }
            .div-block{
                margin-left: 48%;
                p{
                    font-family: regular;
                }
                img{
                    width: 100px;
                    height: 32px;
                    margin-top: -34px;
                }
                label{
                    color:#0f878c;
                    font-size: 15px;
                }
            }
        }
        .Detail-row-sec{
            display:flex;
            width: 100%;
            margin-left: 2%;
            margin-top: -50px;
            .col-md-3{
                background-color: white;
                border:1px solid lightgray;
                margin-right: 10px;
                label{
                    font-size: 15px;
                    font-family: bold;
                }
                p{
                    font-size: 12px;
                    font-family: regular;
                }
                #label-value{
                    font-size: 35px;
                    margin-top: 8px;
                }
            }
        }
        .pageData{
            border:1px solid lightgray;
            width:98%;
            margin-left: 1%;
            margin-top: 1rem;
            padding:1rem;
            .first-row{
                display: flex;
                img{
                    width: 50px;
                    height: 60px;
                }
                p{
                    font-size:20px;
                    font-family: bold;
                    span{
                        font-size: 15px;
                        font-family: regular;
                    }
                }
            }
            .second-row{
                .col-first{
                    display: flex;
                    img{
                        width: 100px;
                        height: 100px;
                    }
                    p{
                        font-family: bold;
                        font-size: 30px;
                        line-height: 1.2rem;
                        margin-left: 20px;
                        #span{
                            font-family: regular;
                            font-size: 15px;
                        }
                        #span1{
                            font-family: bold;
                            font-size: 15px;
                        }
                        #span2{
                            font-family: bold;
                            color: #0f878c;
                            font-size: 18px;
                        }
                        #span3{
                            font-family: regular;
                            color: #1E93BE;
                            font-size: 12px;
                            cursor: pointer;                            
                        }
                    }
                }
                .col-second{
                    display: flex;
                    margin-top: 2rem;
                    img{
                        width: 40px;
                        margin-left: 50%;
                    }
                }
            }
            .col-third{
                margin-top: 2rem;
                .col-md-3{
                    p{
                        font-family: italic;
                        font-size: 15px;
                    }
                }
                .col-md-9{
                    p{
                        font-family: bold;
                        font-size: 15px;
                    }
                }
            }
            .col-third1{
                margin-top: 0.8rem;
                .col-md-3{
                    p{
                        font-family: italic;
                        font-size: 15px;
                    }
                }
                .col-md-9{
                    p{
                        font-family: bold;
                        font-size: 15px;
                    }
                }
            }
            .col-fourth{
                margin-top: 5rem;
                button{
                    padding: 5px 20px;
                    // color: #FFD069;
                    color: lightgrey;
                    font-family: bold;
                    background-color: #21201C;
                    border: 1px solid #21201C;
                    margin-left: 60%;
                    border-radius: 3px;
                }
            }
        }
    }

    @media only screen and (max-width: 750px){
        .lg-page-banner{
            display: none;
            // width: 0%;
            
        }

        .lg-page-form{
            width: 100%;
        }
    }
    

    

}



